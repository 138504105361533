<template>
  <div>
      <a-modal v-model:visible="visible" width="50%" title="语音" @ok="hideModal" @cancel="hideModal">
        <VoiceView ref="voiceView"/>
      </a-modal>
  </div>
</template>
<script>
import { ref } from 'vue';
import VoiceView from "@/components/VoiceView";
const visible = ref(false);
export default {
  name: 'VoiceModal',
  components: {
    VoiceView

  },
  setup(){
    return {
      visible,
    };
  },
  methods:{
    showModal(){
      visible.value = true;
    },
    hideModal(){
      visible.value = false;
      this.$refs.voiceView.stopAllVoice();
      this.$refs.voiceView.destroyWave();
      this.$refs.voiceView.clearData();
      this.$refs.voiceView.initCollapseActiveKey();
    },
    async getVoiceGroupList (heroId,nodeType) {
      this.$nextTick(() => {
        this.$refs.voiceView.getVoiceGroupList(heroId,nodeType);
      })
    }
  }
}
</script>
