<template>

  <section data-name="slide-4" class="slide fade-6 kenBurns">
    <div class="content">
      <div class="container">
        <div class="wrap">

          <div class="fix-6-12">
            <h1 class="huge ae-1 margin-bottom-2" style="font-weight: 350;color: #E5E5E5">下载Morii(移动端暂停更新)</h1>
            <p class="hero ae-2 margin-bottom-3">
              <span class="opacity-8">安卓系统请先安装ARCore，
                <a-popover placement="right">
                  <template #content>
                    <img src="../../assets/img/ar_core_code.png" height="100"/>
                  </template>
                  <a style="text-decoration: underline;" href="https://ow-club.oss-cn-shanghai.aliyuncs.com/ARPhoto/apk/arcore-1-35-223110323.apk">点此下载！</a>
                </a-popover>
              </span>
            </p>

            <a href="https://www.onigenji.com/OWBuild.zip" class="button hollow ae-5"><img src="../../assets/img/bg-win.png" height="63"/></a>

            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

            <a-popover placement="bottom">
              <template #content>
                <img src="../../assets/img/ios_code.png" height="100"/>
              </template>
              <a href="https://apps.apple.com/cn/app/morii/id6444820811" class="button hollow ae-5"><img src="../../assets/img/iOS.png" height="63"/></a>
            </a-popover>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

            <a-popover placement="bottom">
              <template #content>
                <img src="../../assets/img/android_code.png" height="100"/>
              </template>
              <a href="https://ow-club.oss-cn-shanghai.aliyuncs.com/ARPhoto/apk/Morii.apk" class="button hollow ae-6"><img src="../../assets/img/android.png" height="63"/></a>
            </a-popover>

          </div>
        </div>
      </div>
    </div>
    <div class="background" style="background-image:url(https://ow-club.oss-cn-shanghai.aliyuncs.com/website/background/img-95.jpg)"></div>
  </section>

</template>

<script>
export default {
name: "DownloadApp",
  methods:{
    async downloadApp(){
      window.open("https://ow-club.oss-cn-shanghai.aliyuncs.com/ARPhoto/apk/Morii.apk")
    },
  }
}
</script>

<style scoped>
.slide:first-child, .slide.after {
  transform: translate3d(0, 0%, 0);
  will-change: transform;
}
.hollow{
}
.banner__download-item i {
  margin-bottom: 4px;
  margin-top: 26px;
}
</style>
