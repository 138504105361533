<template>
  <a-layout theme="light">
    <a-layout-header class="my-layout-header" :style="{ position: 'fixed', zIndex: 999, width: '100%',height:'55px'}">
      <div class="my-logo">
        <div class="my-logo-text"><b>OW Wiki</b></div>
      </div>
      <a-menu
          theme="dark"
          mode="horizontal"
          class="my-menu"
          :selectedKeys="selectedKeys"
          :style="{ lineHeight: '55px'}">
        <a-menu-item class="my-menu-item" key="1" @click="changeMenu('home','1')">首页</a-menu-item>
<!--
        <a-menu-item class="my-menu-item" disabled key="4">英雄</a-menu-item>
-->
        <a-menu-item class="my-menu-item" key="5" @click="changeMenu('map','5')">地图</a-menu-item>
        <a-menu-item class="my-menu-item" key="2" @click="changeMenu('voice','2')">语音</a-menu-item>
        <a-menu-item class="my-menu-item" key="2-1" @click="changeMenu('npcvoice','2-1')">NPC语音(Beta)</a-menu-item>
<!--
        <a-menu-item class="my-menu-item" key="3"  @click="changeMenu('morii','3')">Morii</a-menu-item>
-->
        <a-menu-item class="my-menu-item" key="6" @click="changeMenu('about','6')">关于</a-menu-item>
      </a-menu>
    </a-layout-header>
    <a-layout-content :style="{ marginTop: '55px' }">
      <router-view></router-view>
    </a-layout-content>
  </a-layout>
</template>

<script>

// import router from "@/router/index"

export default {
  name: "MyLayout",
  data() {
    return {
      selectedKeys: []
    }
  },
  methods:{
    //路由内容切换
    changeMenu(route,key){
      console.log(this.$router)
      //获取路由对象并切换
      this.$router.push('/' + route);
      console.log(key)
      this.selectedKeys = [key];
    },
  },
  watch:{
    '$route'(to){
      console.log(to)
      this.selectedKeys = [to.name];
      if(to.name == '5-1'){
        this.selectedKeys = ['5'];
      }
      if(to.name == '0'){
        this.selectedKeys = ['1'];
      }
    }
  },
}
</script>

<style scoped>
[data-theme='dark'] .site-layout .site-layout-background {
  background: #000000;
}
.my-layout-header {
  background : #2c3e50 !important;
}
.my-menu{
  background : #2c3e50 !important;
  float: right;
}

.my-menu-item{
  width: 100px;
  text-align: center;
}

.my-logo {
  position: fixed;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  margin: 5px;
  background-image: url("../assets/wuzi.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.my-logo-text{
  line-height: 10px;
  font-size: 20px;
  margin-left: 55px;
  margin-top: 20px;
  width: 200px;
  text-align: left;
  color: aliceblue;
  font-family: "Lato", sans-serif;
}
.ant-layout{
  background: #00000000;
}
</style>
