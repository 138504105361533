<template>
  <a-spin style="position: fixed;z-index: 9999;margin-top: 10%" :spinning="spinning" tip="加载中，请稍后...">
    <a-input-search
        v-model:value="searchVal"
        placeholder="请输入关键字（暂时只支持中文！）"
        enter-button
        @search="onSearch" style="width: 40%;margin: 0 auto;z-index: 10;padding-top: 30px;padding-bottom: 10px" compact>
<!--      <template #addonBefore>-->
<!--        <a-select v-model:value="searchTypeText" style="width: 80px" @change="onSearchTypeChange">-->
<!--          <a-select-option value="1">对话</a-select-option>-->
<!--          <a-select-option value="2">语音</a-select-option>-->
<!--        </a-select>-->
<!--      </template>-->
    </a-input-search>
    <div style="width: 50%;height: 100%;margin: 0 auto">
      <a-list
          class="comment-list"
          item-layout="horizontal"
          :data-source="dataSource">
        <template #renderItem="{item}">
          <a-list-item style="display: list-item;">
            <a-comment :avatar="item.avatar" @click="playVoice(item.cindex)" style="float: left;top: -18px;"></a-comment>
            <div class="messages-chat" style="display: flex">
              <p :id="'waveform' + '-' + item.id" style="width: 100%;text-align: left;">
                <span class="voice-text" v-html="item.audioText"></span>
              </p>
            </div>
<!--            <a href="" style="float: right;position: relative;right: -80px;top:-57px;color: #7b6ffc">打开互动</a>-->
          </a-list-item>
        </template>

      </a-list>
      <a-pagination style="text-align: center;padding-top: 20px" v-if="dataSource.length > 0" v-model:current="pageNum" :total="dataTotal" :defaultPageSize="pageSize" @change="changePageOrSize"/>
    </div>
  </a-spin>
</template>

<script>
import WaveSurfer from "wavesurfer.js";
import axios from "axios";
import {message} from "ant-design-vue";

const apiPrefix = process.env.VUE_APP_API_PREFIX //请求前缀

const api = {
  searchVoiceURL: apiPrefix + "/wodata/searchVoice",
};

export default {
  name: "SerachView",
  data() {
    return {
      waveList: [],
      dataSource:[],
      clickTimes:0,
      preIndex:1,
      spinning:true,
      pageNum: 1,
      pageSize:5,
      dataTotal:0,
      heroId:0,
      groupId:0,
      thisPageSearchVal:'',
      searchVal:'',
      searchType:2,
      searchTypeText:'对话',
    }
  },
  mounted() {
    this.searchVal = this.$route.query.searchText;
    this.requestVoiceDataAndInitWave(this.$route.query.searchText);
  },
  beforeUnmount(){
    this.stopAllVoice();
  },
  methods: {
    clearData(){
      this.dataSource = [];
      this.waveList = [];
      this.pageSize = 5;
      this.pageNum = 1;
      this.dataTotal = 0;
      this.heroId = 0;
      this.groupId = 0;
    },
    playVoice(index){
      console.log("playVoice" + index)
      for (var i=0; i < this.waveList.length;i++)
      {
        this.waveList[i].stop()
      }
      if(index == 0){
        this.waveList[index].play()
      }else{
        this.waveList[index - 1].play()
      }
      console.log(this.waveList[index - 1])
      this.repeatedlyClickFun(index);
    },
    repeatedlyClickFun(clickIndex){//连点事件
      if(this.preIndex != clickIndex){
        this.clickTimes = 0;
      }
      let _this = this
      _this.clickTimes++;
      if (_this.clickTimes === 5) {
        _this.clickTimes = 0;
        //  处理多次点击事件...
        window.open(this.dataSource[clickIndex - 1].audioUrl)
      }
      this.preIndex = clickIndex;
    },
    stopAllVoice(){
      for (var i=0; i < this.waveList.length;i++)
      {
        this.waveList[i].stop()
      }
    },
    destroyWave(){
      for (var i=0; i < this.waveList.length;i++)
      {
        this.waveList[i].destroy()
      }
      this.waveList = [];
    },
    setSourceIdAndTargetId(sourceId,targetId){
      this.sourceId = sourceId;
      this.targetId = targetId;
    },
    initWavesurffer(containerId,audioUrl){
      console.log(containerId);
      this.$nextTick(() => {
        var wavesurfer = WaveSurfer.create({
          container: "#" + containerId,
          waveColor: 'violet',
          progressColor: 'purple',
          height:"50",
          interact:false,
          hideScrollbar:true,
        });
        wavesurfer.load(audioUrl);
        this.waveList.push(wavesurfer);
      })
    },
    eventToWavesurfer(currentWave){//绑定连播事件
      var nextWaveNum = this.waveList.indexOf(currentWave) + 1;

      if(nextWaveNum < this.waveList.length){
        var nextWave = this.waveList[nextWaveNum];
        currentWave.on('finish', function () {
          nextWave.play()
        });
      }
    },
    async requestVoiceDataAndInitWave(searchText){
      this.dataSource = [];
      this.waveList = [];
      var that = this;
      that.spinning = true;
      this.$nextTick(() => {
          axios.get(api.searchVoiceURL,{params:{searchType:this.searchType,searchText:searchText,pageNum:this.pageNum,pageSize:this.pageSize}}).then((response) =>  {
          this.dataSource = response.data.result.list;
          this.dataTotal = response.data.result.list[0].dataTotal;
          for (var i=0;i < this.dataSource.length;i++)
          {
            console.log(this.dataSource[i].id);
            this.initWavesurffer('waveform' + '-' + this.dataSource[i].id,this.dataSource[i].audioUrl);
          }
          this.spinning = false;
        }).catch(function (error) {
          console.log(error);
          that.spinning = false;
        });
      });
    },
    initCollapseActiveKey(){
      this.activeKey = 1;
    },
    collapseChangeCallBack(){
      this.$nextTick(() => {
        var that = this;
        setTimeout(function (){
          that.destroyWave()
          if(that.activeKey != null){
            that.requestDataAndInitWave(that.heroId,that.voiceGroupList[that.activeKey - 1].id,that.activeKey)
          }
        }, 200);
      })
    },
    changePageOrSize(pageNum, pageSize){
      this.stopAllVoice();
      this.pageNum = pageNum;
      this.pageSize = pageSize;
      if(this.thisPageSearchVal == '' || this.thisPageSearchVal == undefined){
        this.requestVoiceDataAndInitWave(this.$route.query.searchText)
      }else{
        this.requestVoiceDataAndInitWave(this.thisPageSearchVal)
      }
    },
    onSearch(searchVal){
      this.pageNum = 1;
      if(searchVal == undefined || searchVal == " "){
        message.error('请输入搜索内容（不能全是空格哦，不然服务器会炸的，不信你试试）！！！');
      }else{
        console.log(this.searchTypeText)
        this.thisPageSearchVal = searchVal;
        this.requestVoiceDataAndInitWave(this.thisPageSearchVal);
      }
    },
    onSearchTypeChange(searchTypeVal){
      this.searchType = searchTypeVal;
      console.log(searchTypeVal)
    }
  }
}

</script>

<style scoped>
.messages-chat{
  background-color: #f6f6f6;
  padding: 10px;
  border-radius: 12px;
  width: calc(100% - 75px)
}
</style>
