<template>
  <a-spin style="position: fixed;z-index: 9999;margin-top: 10%" :spinning="spinning" tip="加载中，请稍后...">
  <a-collapse v-model:activeKey="activeKey" accordion @change="collapseChangeCallBack">
    <a-collapse-panel :header="`${voiceGroupList[i-1].groupText}`" v-for="i in voiceGroupList.length" :key="i" >
      <a-list
          class="comment-list"
          item-layout="horizontal"
          :data-source="dataSource">
        <template #renderItem="{item}">
          <a-list-item style="display: list-item;">
            <a-comment :avatar="item.avatar" @click="playVoice(item.cindex)" style="float: left;top: -18px;"></a-comment>
            <div class="messages-chat" style="display: flex">
              <p :id="'waveform' + i + '-' + item.id" style="width: 100%">

                <span class="voice-text">{{ item.audioText }}</span>
                <br v-if="item.audioText2 != null"/>

                <span class="voice-text" style="color: #a44aff" v-if="item.audioText2 != null">{{ item.audioText2 }}</span>
                <br v-if="item.audioText3 != null"/>

                <span class="voice-text" style="color: #7b6ffc" v-if="item.audioText3 != null">{{ item.audioText3 }}</span>
                <br v-if="item.audioText4 != null"/>

                <span class="voice-text" style="color: #be6d18" v-if="item.audioText4 != null">{{ item.audioText4 }}</span>
                <br v-if="item.audioText5 != null"/>

                <span class="voice-text" style="color: #c13e77" v-if="item.audioText5 != null">{{ item.audioText5 }}</span>
              </p>
            </div>
          </a-list-item>
        </template>

      </a-list>
    </a-collapse-panel>
  </a-collapse>
  </a-spin>

  <a-pagination style="text-align: center;padding-top: 20px" v-if="voiceGroupList.length > 0 && voiceGroupList[0].id < 0" v-model:current="pageNum" :total="dataTotal" :defaultPageSize="pageSize" @change="changePageOrSize"/>

</template>
<script>
import { defineComponent, ref, watch } from 'vue';
import axios from "axios";
import WaveSurfer from "wavesurfer.js";

const apiPrefix = process.env.VUE_APP_API_PREFIX //请求前缀

const api = {
  getVoiceGroupListURL: apiPrefix + "/wodata/getVoiceGroupList",
  getVoiceListURL: apiPrefix + "/wodata/getMultiLanguageVoiceList",
};

export default defineComponent({
  setup() {
    const text = `A dog is a type of domesticated animal.Known for its loyalty and faithfulness,it can be found as a welcome guest in many households across the world.`;
    const activeKey = ref(['1']);
    watch(activeKey, val => {
      console.log(val);
    });
    return {
      text,
      activeKey,
    };
  },
  data() {
    return {
      waveList: [],
      dataSource:[],
      isLoop:false,
      voiceGroupList:[],
      clickTimes:0,
      preIndex:1,
      spinning:true,
      pageNum: 1,
      pageSize:5,
      dataTotal:0,
      heroId:0,
      groupId:0,
    }
  },
  beforeUnmount(){
    this.stopAllVoice();
  },
  methods:{
    clearData(){
      this.dataSource = [];
      this.waveList = [];
      this.pageSize = 5;
      this.pageNum = 1;
      this.dataTotal = 0;
      this.heroId = 0;
      this.groupId = 0;
    },
    playVoice(index){
      console.log("playVoice" + index)
      for (var i=0; i < this.waveList.length;i++)
      {
        this.waveList[i].stop()
      }
      if(index == 0){
        this.waveList[index].play()
      }else{
        this.waveList[index - 1].play()
      }
      console.log(this.waveList[index - 1])
      this.repeatedlyClickFun(index);
    },
    repeatedlyClickFun(clickIndex){//连点事件
      if(this.preIndex != clickIndex){
        this.clickTimes = 0;
      }
      let _this = this
      _this.clickTimes++;
      if (_this.clickTimes === 5) {
        _this.clickTimes = 0;
        //  处理多次点击事件...
        window.open(this.dataSource[clickIndex - 1].audioUrl)
      }
      this.preIndex = clickIndex;
    },
    stopAllVoice(){
      for (var i=0; i < this.waveList.length;i++)
      {
        this.waveList[i].stop()
      }
    },
    destroyWave(){
      for (var i=0; i < this.waveList.length;i++)
      {
        this.waveList[i].destroy()
      }
      this.waveList = [];
    },
    setSourceIdAndTargetId(sourceId,targetId){
      this.sourceId = sourceId;
      this.targetId = targetId;
    },
    initWavesurffer(containerId,audioUrl,audioIndex){
      console.log(containerId);
      this.$nextTick(() => {
        var wavesurfer = WaveSurfer.create({
          container: "#" + containerId,
          waveColor: 'violet',
          progressColor: 'purple',
          height:"50",
          interact:false,
          hideScrollbar:true,
        });
        wavesurfer.load(audioUrl);
        this.waveList.push(wavesurfer);

        var that = this;

        var isContinuationLocal = localStorage.getItem("isContinuation");
        if(isContinuationLocal == 1){
          wavesurfer.on('ready', function () {
            //绑定连播事件
            that.eventToWavesurfer(wavesurfer);
          });
        }

        if(audioIndex == 0 && isContinuationLocal == 1){
          wavesurfer.on('ready', function () {
            that.playVoice(0);
          });
        }
      })
    },
    eventToWavesurfer(currentWave){//绑定连播事件
      var nextWaveNum = this.waveList.indexOf(currentWave) + 1;

      if(nextWaveNum < this.waveList.length){
        var nextWave = this.waveList[nextWaveNum];
        currentWave.on('finish', function () {
          nextWave.play()
        });
      }
    },
    async getVoiceGroupList (heroId,nodeType) {
      this.spinning = true
      var that = this;
      this.$nextTick(() => {
        var audioCode = localStorage.getItem("AudioCode")
        axios.get(api.getVoiceGroupListURL,{params:{heroId:heroId,languageCode:audioCode,nodeType:nodeType}}).then((response) =>  {
          this.voiceGroupList = response.data.result.list;
          if(this.voiceGroupList.length > 0){
            this.heroId = heroId;
            this.groupId = this.voiceGroupList[0].id;
            this.requestDataAndInitWave(heroId,this.voiceGroupList[0].id,this.activeKey)
          }
          console.log()
        }).catch(function (error) {
          console.log(error);
          that.spinning = false;
        });
      })
    },
    requestDataAndInitWave(heroId,groupId,tabId){
      this.dataSource = [];
      this.waveList = [];
      var that = this;
      that.spinning = true;
      var audioCode = localStorage.getItem("AudioCode")
      var textCode = JSON.parse(localStorage.getItem("TextCodeNew"))+''
      axios.get(api.getVoiceListURL,{params:{heroId:heroId,groupId:groupId,languageCode:audioCode,textCode:textCode,pageNum:this.pageNum,pageSize:this.pageSize}}).then((response) =>  {
        this.dataSource = response.data.result.list;
        this.dataTotal = response.data.result.list[0].dataTotal;
        for (var i=0;i < this.dataSource.length;i++)
        {
          console.log(this.dataSource[i].id);
          this.initWavesurffer('waveform' + tabId + '-' + this.dataSource[i].id,this.dataSource[i].audioUrl,i);
        }
        this.spinning = false;
      }).catch(function (error) {
        console.log(error);
        that.spinning = false;
      });
    },
    initCollapseActiveKey(){
      this.activeKey = 1;
    },
    collapseChangeCallBack(){
      this.$nextTick(() => {
        var that = this;
        setTimeout(function (){
          that.destroyWave()
          if(that.activeKey != null){
            that.requestDataAndInitWave(that.heroId,that.voiceGroupList[that.activeKey - 1].id,that.activeKey)
          }
        }, 200);
      })
    },
    changePageOrSize(pageNum, pageSize){
      this.stopAllVoice();
      this.pageNum = pageNum;
      this.pageSize = pageSize;
      this.requestDataAndInitWave(this.heroId,this.groupId,this.activeKey)
    }
  }
});
</script>
