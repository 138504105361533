<template>
  <a-spin style="position: fixed;z-index: 9999;margin-top: 10%" :spinning="spinning" tip="加载中，请稍后...">
  <a-tabs
      v-model:activeKey="activeKey"
      @tabScroll="callback"
      @tabClick="tabClickCallBack"
  >
    <a-tab-pane v-for="i in groupIdList.length" :key="i" :tab="`互动-${i}`">
      <a-list
          class="comment-list"
          item-layout="horizontal"
          :data-source="dataSource">
        <template #renderItem="{item}">
          <a-list-item style="display: list-item;">

            <a-comment v-if="item.type == 1">
              <template #content>
                <a-comment :avatar="item.avatar" class="right-avatar" @click="playVoice(item.cindex)"></a-comment>
                <p :id="'waveform' + i + '-' + item.cindex" class="messages-chat right-chat">
                  <span class="voice-text">{{ item.audioText }}</span>
                  <br v-if="item.audioText2 != null"/>

                  <span class="voice-text" style="color: #a44aff" v-if="item.audioText2 != null">{{ item.audioText2 }}</span>
                  <br v-if="item.audioText3 != null"/>

                  <span class="voice-text" style="color: #7b6ffc" v-if="item.audioText3 != null">{{ item.audioText3 }}</span>
                  <br v-if="item.audioText4 != null"/>

                  <span class="voice-text" style="color: #be6d18" v-if="item.audioText4 != null">{{ item.audioText4 }}</span>
                  <br v-if="item.audioText5 != null"/>

                  <span class="voice-text" style="color: #c13e77" v-if="item.audioText5 != null">{{ item.audioText5 }}</span>

                </p>
              </template>
            </a-comment>

            <div  v-if="item.type == 2">
                <a-comment :avatar="item.avatar" @click="playVoice(item.cindex)" style="float: left;top: -18px;"></a-comment>
                <div class="messages-chat" style="display: flex">
                  <p :id="'waveform' + i + '-' + item.cindex" style="width: 100%">

                    <span class="voice-text">{{ item.audioText }}</span>
                    <br v-if="item.audioText2 != null"/>

                    <span class="voice-text" style="color: #a44aff" v-if="item.audioText2 != null">{{ item.audioText2 }}</span>
                    <br v-if="item.audioText3 != null"/>

                    <span class="voice-text" style="color: #7b6ffc" v-if="item.audioText3 != null">{{ item.audioText3 }}</span>
                    <br v-if="item.audioText4 != null"/>

                    <span class="voice-text" style="color: #be6d18" v-if="item.audioText4 != null">{{ item.audioText4 }}</span>
                    <br v-if="item.audioText5 != null"/>

                    <span class="voice-text" style="color: #c13e77" v-if="item.audioText5 != null">{{ item.audioText5 }}</span>
                  </p>
                </div>
            </div>

          </a-list-item>
        </template>
      </a-list>
    </a-tab-pane>
  </a-tabs>
  </a-spin>
</template>
<script>
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import WaveSurfer from 'wavesurfer.js';
import axios from "axios";
import { ref } from 'vue';

const apiPrefix = process.env.VUE_APP_API_PREFIX //请求前缀

const api = {
  getConversationListURL: apiPrefix + "/wodata/getMultiLanguageConversationList",
  getGroupListURL: apiPrefix + "/wodata/getGroupList",
};

dayjs.extend(relativeTime);
export default {
  name:"ChatView",
  data() {
    return {
      waveList: [],
      dataSource:[],
      isLoop:false,
      groupIdList:[],
      clickTimes:0,
      preIndex:1,
      spinning:true
    }
  },
  setup() {
    const activeKey = ref(1);
    const callback = val => {
      console.log(val);
    };
    return {
      dayjs,
      activeKey,
      callback,
    };
  },
  mounted() {
    // this.getConversationList();
  },
  beforeUnmount(){
    this.stopAllVoice();
  },
  methods:{
    clearData(){
      this.waveList = [];
    },
    playVoice(index){
      for (var i=0; i < this.waveList.length;i++)
      {
        this.waveList[i].stop()
      }
      if(index == 0){
        this.waveList[index].play()
      }else{
        this.waveList[index - 1].play()
      }
      this.repeatedlyClickFun(index);
    },
    repeatedlyClickFun(clickIndex){//连点事件
      if(this.preIndex != clickIndex){
        this.clickTimes = 0;
      }
      let _this = this
      _this.clickTimes++;
      if (_this.clickTimes === 5) {
        _this.clickTimes = 0;
        //  处理多次点击事件...
        window.open(this.dataSource[clickIndex - 1].audioUrl)
      }
      this.preIndex = clickIndex;
    },
    stopAllVoice(){
      for (var i=0; i < this.waveList.length;i++)
      {
        this.waveList[i].stop()
      }
    },
    destroyWave(){
      for (var i=0; i < this.waveList.length;i++)
      {
        this.waveList[i].destroy()
      }
      this.waveList = [];
    },
    setSourceIdAndTargetId(sourceId,targetId){
      this.sourceId = sourceId;
      this.targetId = targetId;
    },
    initWavesurffer(containerId,audioUrl,audioIndex){
      this.$nextTick(() => {
        var wavesurfer = WaveSurfer.create({
          container: "#" + containerId,
          waveColor: 'violet',
          progressColor: 'purple',
          height:"50",
          interact:false,
          hideScrollbar:true,
        });
        wavesurfer.load(audioUrl);
        this.waveList.push(wavesurfer);

        var that = this;

        var isContinuationLocal = localStorage.getItem("isContinuation");
        if(isContinuationLocal == 1) {
          wavesurfer.on('ready', function () {
            //绑定连播事件
            that.eventToWavesurfer(wavesurfer);
          });
        }

        if(audioIndex == 0 && isContinuationLocal == 1){
          wavesurfer.on('ready', function () {
            that.playVoice(0);
          });
        }
      })
    },
    eventToWavesurfer(currentWave){//绑定连播事件
      var nextWaveNum = this.waveList.indexOf(currentWave) + 1;

      if(nextWaveNum < this.waveList.length){
        var nextWave = this.waveList[nextWaveNum];
        currentWave.on('finish', function () {
          nextWave.play()
        });
      }
    },
    async getConversationList (sourceId,targetId) {
      var that = this;
      this.spinning = true
        this.$nextTick(() => {
          var audioCode = localStorage.getItem("AudioCode")
          axios.get(api.getGroupListURL,{params:{sourceId:sourceId,targetId:targetId,languageCode:audioCode}}).then((response) =>  {
            console.log(response.data.result.list);
            this.groupIdList = response.data.result.list;
            if(this.groupIdList.length > 0){
              this.requestDataAndInitWave(this.groupIdList[0],this.activeKey)
            }
            console.log()
          }).catch(function (error) {
            console.log(error);
            that.spinning = false;
          });
      })
    },
    requestDataAndInitWave(groupId,tabId){
      var that = this;
      var audioCode = localStorage.getItem("AudioCode")
      var textCode = JSON.parse(localStorage.getItem("TextCodeNew").toString()) + '';
      axios.get(api.getConversationListURL,{params:{groupId:groupId,audioLanguageCode:audioCode,textLanguageCode:textCode}}).then((response) =>  {
        this.dataSource = response.data.result.list;
        console.log(response.data.result.list);
        for (var i=0;i < this.dataSource.length;i++)
        {
          this.initWavesurffer('waveform' + tabId + '-' + this.dataSource[i].cindex,this.dataSource[i].audioUrl,i);
        }
        this.spinning = false;
      }).catch(function (error) {
        console.log(error);
        that.spinning = false;
      });
    },
    initTabActiveKey(){
      this.activeKey = 1;
    },
    tabClickCallBack(){
      this.$nextTick(() => {
        this.destroyWave()
        this.requestDataAndInitWave(this.groupIdList[this.activeKey - 1],this.activeKey)
        this.spinning = true;
      })
    }
  }
};
</script>

<style>
.messages-chat{
  background-color: #f6f6f6;
  padding: 10px;
  border-radius: 12px;
  width: 70%;
}
.right-chat{
  float: right;
  margin-right: 0px !important;
  background-color:#e3effd !important
}
.right-avatar{
  float: right;
  margin-top: -20px;
  margin-left: 10px
}
.ant-comment-avatar img{
  width: 60px !important;
  height: 60px !important;
}
.voice-text{
  padding-top: 10px;
}
</style>
