<template>
  <div>
    <a-modal v-model:visible="visible" width="45%" title="关于" @ok="hideModal" @cancel="hideModal">
      <p style="font-size: 18px">该项目不隶属于暴雪娱乐公司。
        站内所有有关《守望先锋》《守望先锋：归来》的内容均为其各自所有者的财产。UP主只是出于热爱整理收录。</p>
<!--      <h1 style="color: red">切勿商用！！！</h1>-->
      <p style="font-size: 18px">会不定期更新内容，制作不易，<a href="https://space.bilibili.com/67787489" target="_blank">B站（<a href="https://space.bilibili.com/67787489" style="text-decoration: underline;color: #3F51B5" target="_blank">https://space.bilibili.com/67787489</a>）</a>关注支持一下！</p>
      <p style="color: #42b983;font-size: 18px">发现bug可直接私信我，我会尽快改掉。</p>
      <p style="color: #42b983;font-size: 20px">有什么建议或意见，可私信我，我会有空的时候实现，大家一起共同维护！</p>
<!--      <p style="color: #cd5c5c;font-size: 18px">热爱自己热爱的，坚持自己坚持的，其实生活也没那么糟糕，不要放弃对生活的热爱和执着，做一个真诚有趣的人。今天记得加油鸭！</p>-->
<!--      <p style="color: #8147fc;font-size: 20px">守望要关服了，只好用这种方式把自己喜欢的东西留下来了，希望你也喜欢</p>-->
      <p style="color: #8147fc;font-size: 22px">B站关注支持下，你的关注是我持续更新的动力！！</p>
      <p style="font-size: 18px">-------change log-------</p>

      <b>v2.2（好久不见）</b>
      <ul>
          <li><b style="color: indianred;font-size: 16px">添加新英雄：生命之梭；</b></li>
          <li><b style="color: indianred;font-size: 16px">添加新地图：南极半岛；</b></li>
          <li>增量更新语音；</li>
          <li>优化查询逻辑。</li>
      </ul>

      <b>v2.1.2</b>
      <ul>
        <li><b style="color: indianred;font-size: 16px">更新win客户端，添游戏模式！</b>
<!--          <a-button type="primary" size="large" @click="changeMenu('morii')">
            <template #icon>
              <DownloadOutlined />
            </template>
            下载
          </a-button>-->
        </li>
        <li>支持一下这条视频：<a href="https://www.bilibili.com/video/BV1AK41117gy/" style="text-decoration: underline;color: #3F51B5" target="_blank">https://www.bilibili.com/video/BV1AK41117gy/</a></li>
      </ul>
      <b>v2.1.1</b>
      <ul>
        <li><b style="color: indianred;font-size: 16px">添加搜索功能；</b>
        </li>
        <li>近期应该不会更新，除非守望更新语音或地图，在忙另外一个项目，感兴趣的可以加群沟通：696168829</li>
      </ul>
      <b style="color: #7b6ffc">v2.0（大更新！！！）</b>
      <ul>
        <li><b style="color: indianred;font-size: 16px">提前祝大家元旦快乐，多陪陪家人！</b></li>
        <li><b style="color: indianred;font-size: 16px">更新win客户端，内容很多，不一一说了，一定要下载看看！！！！！</b>
<!--          <a-button type="primary" size="large" @click="changeMenu('morii')">-->
<!--            <template #icon>-->
<!--              <DownloadOutlined />-->
<!--            </template>-->
<!--            下载-->
<!--          </a-button>-->
        </li>
<!--        <li>支持一下这条视频：<a href="https://www.bilibili.com/video/BV1XD4y1j75t/" style="text-decoration: underline;color: #3F51B5" target="_blank">https://www.bilibili.com/video/BV1XD4y1j75t/</a></li>-->
        <li>更新部分语音。</li>
      </ul>
      <b>v1.1.6</b>
      <ul>
        <li><b style="color: indianred;font-size: 16px">添加win客户端，由于web端受限，英雄展示将通过win客户端（将尽量还原ow内的shader，当前还只是Beta版）；</b>
<!--          <a-button type="primary" size="large" @click="changeMenu('morii')">-->
<!--            <template #icon>-->
<!--              <DownloadOutlined />-->
<!--            </template>-->
<!--            下载-->
<!--          </a-button>-->
        </li>
        <li>可以去看下这条视频：<a href="https://www.bilibili.com/video/BV1wG4y1n7JA/" style="text-decoration: underline;color: #3F51B5" target="_blank">https://www.bilibili.com/video/BV1wG4y1n7JA/</a></li>
        <li>更新部分语音。</li>
      </ul>
      <b>v1.1.5</b>
      <ul>
        <li><b style="color: indianred;font-size: 16px">添加Morii app；</b>
<!--          <a-button type="primary" size="large" @click="changeMenu('morii')">-->
<!--            <template #icon>-->
<!--              <DownloadOutlined />-->
<!--            </template>-->
<!--            下载APP-->
<!--          </a-button>-->
        </li>
        <li><b style="color: indianred;font-size: 16px">更新语音。</b></li>
      </ul>
      <b>v1.1.4</b>
      <ul>
        <li><b style="color: indianred;font-size: 18px">添加新英雄"拉玛刹"的所有对话语音和独白语音；</b></li>
        <li><b style="color: indianred;font-size: 18px">添加NPC语音模块（Beta版，还要有好多没有完善）；</b></li>
        <li>添加新地图"香巴里"的地图开场音乐；</li>
        <li>增量更新所有英雄的语音，匹配ow-2.2.0.2.107804版本（后面会计划把新增的语音标记）；</li>
        <li>首页添加"特别嘉奖"模块，显示的是受嘉奖者B站的主页信息。（如果提出具有建设性的意见或建议被采纳时会被加到"特别嘉奖"，此模块将会长期存在）；</li>
        <li>添加连播开关，不需要连播的可以关闭，<b>点击头像可以播放语音</b>；</li>
        <li>修复若干bug，不一一罗列了；</li>
        <li>优化数据存储的流程。</li>
      </ul>
      <b>v1.1.3</b>
      <ul>
        <li><b style="color: indianred;font-size: 18px">添加各语言文本对照，想学外语的同学有福了（这个建议是b站小伙伴提的，具体哪位我找不到了，你要是看到可以主动找我一下，这么好的建议必须表扬！！！）；</b></li>
        <li>添加雾子和源氏神话皮肤的大厅背景音乐，在这里：<a href="https://www.onigenji.com/#/map" target="_blank">https://www.onigenji.com/#/map；</a></li>
        <li>增量更新语音线和语音对话；</li>
        <li>添加loading，数据很大，服务器很差，适当等它一下；</li>
        <li>修复语音播放界面点击非头像区域时仍会触发重播的bug；</li>
        <li>删除非必要的文件，减少服务器压力；</li>
        <li>优化前端访问速度；</li>
        <li>优化后端，优化代码；</li>
        <li>最近在做另外一个项目，可以关注一下参与测试。</li>
      </ul>
      <b>v1.1.2</b>
      <ul>
        <li><b style="color: indianred;font-size: 20px">添加守望1、2内的地图音乐；</b></li>
        <li>添加新版本的语音线；</li>
        <li>添加新版本的语音条；</li>
        <li>添加部分语音分类，简化分类名称；</li>
        <li>修复部分对话语音关联出错的问题；</li>
        <li>修复浏览器后退音频仍在播放的问题；</li>
        <li>优化压缩图像，访问更快；</li>
        <li>优化音频大小，质量保持不变；</li>
        <li>提升性能。</li>
      </ul>
      <b>v1.1.1</b>
      <ul>
        <li><b style="color: indianred">点击图谱中的英雄头像可以展开英雄的语音；</b></li>
        <li>添加英雄语音；</li>
        <li>数据迁移新服务器，访问速度更快；</li>
        <li>添加日语、韩语、台湾音频；</li>
        <li>修复部分语音的对应关系；</li>
        <li>完善查询逻辑；</li>
        <li>优化音频播放；</li>
        <li>优化音频的列表显示；</li>
        <li>完善折叠框的展示，优化性能。</li>
      </ul>
      <b>v1.1.0</b>
      <ul>
        <li>前后台逻辑基本重写，wiki框架基本建成；</li>
        <li>添加语音图谱的全选和全不选的功能</li>
        <li>添加多种文本语言切换功能；</li>
        <li>添加下载功能（语音播放界面，连点头像5次可下载）；</li>
        <li>添加【首页】页面，暂时没有想好放些什么，有想法的同学可以私信我；</li>
        <li>添加【英雄】菜单，初步计划用来收录英雄的人物介绍、技能信息和相关故事线；</li>
        <li>添加【地图】菜单，初步计划用来收录地图的相关信息；</li>
        <li>添加【图像】菜单，初步计划用来收录图像数据；</li>
        <li>添加【关于】菜单，每次更版信息会在这里记录；</li>
        <li>修复音频有时无法自动播放的问题；</li>
        <li>优化音频可视化显示；</li>
        <li>优化音频的对应关系。</li>
      </ul>
      <b>v1.0.1</b>
      <ul>
        <li>添加中文版的音频，并添加了PVE模式的对话语音；</li>
        <li>添加语言筛选功能；</li>
        <li>添加关于说明；</li>
        <li>完善英语版的音频，压缩音频大小；</li>
        <li>优化网站，修复若干bug。</li>
      </ul>
      <b>v1.0.0</b>
      <ul>
        <li>初版本发布。</li>
      </ul>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
const visible = ref(false);

export default defineComponent({
  setup(){
    return {
      visible,
    };
  },
  methods:{
    showModal(){
      visible.value = true;
    },
    hideModal(){
      localStorage.setItem("IsReadTip0422", "1");
      visible.value = false;
    },
    //路由内容切换
    changeMenu(route,key){
      this.hideModal();
      console.log(this.$router)
      //获取路由对象并切换
      this.$router.push('/' + route);
      console.log(key)
      this.selectedKeys = [key];
    },
  }
});
</script>

<style>
p{
  margin-bottom: 20px;
}
</style>
