import {createRouter, createWebHashHistory} from 'vue-router'
import VoiceData from '@/components/VoiceData.vue'
import HomeView from '@/components/HomeView.vue'
import AboutView from '@/components/AboutView.vue'
import MapList from "@/components/map/MapList";
import MapDetails from "@/components/map/MapDetails";
import NpcEChartsRelation from "@/components/NpcEChartsRelation";
import DownloadApp from "@/components/morii/DownloadApp";
import SerachView from "@/components/SerachView";


const routes = [
    {
        name:"0",
        path: '/',
        component:HomeView
    },
    {
        name:"1",
        path: '/home',
        component:HomeView
    },
    {
        name:"2",
        path: '/voice',
        component:VoiceData
    },
    {
        name:"2-1",
        path: '/npcvoice',
        component:NpcEChartsRelation
    },
    {
        name:"5",
        path: '/map',
        component:MapList
    },
    {
        name:"5-1",
        path: '/mapDetails',
        component:MapDetails
    },
    {
        name:"6",
        path: '/about',
        component:AboutView
    },
    {
        name:"3",
        path: '/morii',
        component:DownloadApp
    },
    {
        name:"7",
        path: '/search',
        component:SerachView
    }
]

const router = createRouter({
    history:createWebHashHistory(),
    routes:routes
})
export default router;
