<template>
  <div style="width: 200px;z-index: 11;position: fixed;margin-top: 15px;margin-left: 10px;display: flex;">文本：</div>
  <a-select
      v-model:value="selectedTextLanguageCode"
      mode="multiple"
      style="min-inline-size: 200px;z-index: 11;position: fixed;margin-top: 10px;margin-left: 50px;display: flex;"
      :options="textLanguageCode"
      @change="handleTextChange"
  />

  <div style="width: 200px;z-index: 10;position: fixed;margin-top: 65px;margin-left: 10px;display: flex;">音频：</div>
  <a-select
      v-model:value="defaultAudioValue"
      label-in-value
      style="width: 200px;z-index: 10;position: fixed;margin-top: 60px;margin-left: 50px;display: flex;"
      :options="audioLanguageCode"
      @change="handleAudioChange"
  />

  <div style="width: 200px;z-index: 10;position: fixed;margin-top: 120px;margin-left: 10px;display: flex;">连播：</div>
  <a-switch @change="continuationChange" v-model:checked="isContinuation" style="z-index: 10;position: fixed;margin-top: 120px;margin-left: 50px;display: flex;"/>

  <a-spin style="z-index: 9999;margin-top: 15%" :spinning="spinning" tip="加载中，请稍后...">
    <!-- 总共93%   -->
    <div id="npc_main" class="container" style="height: 93%;width: 100%">
    </div>
  </a-spin>
  <ChatModal ref="chatModal"/>
  <VoiceModal ref="voiceModal"/>
</template>

<script>
import * as echarts from "echarts";
import ChatModal from "@/components/ChatModal";
import axios from "axios";
import VoiceModal from "@/components/VoiceModal";
import { message } from 'ant-design-vue';

const apiPrefix = process.env.VUE_APP_API_PREFIX //请求前缀

const api = {
  getHeroListURL: apiPrefix + "/wodata/getHeroList",
  getRelationshipListURL: apiPrefix + "/wodata/getRelationshipList",
  getConversationListURL: apiPrefix + "/wodata/getConversationList",
  getNpcListURL: apiPrefix + "/wodata/getNpcList",
  getTextInternationalizationURL: apiPrefix + "/internationalization/getTextInternationalization",
  getAudioInternationalizationURL: apiPrefix + "/internationalization/getAudioInternationalization",
};

let npc_nodes = [];
let npc_categories = [];
var npc_chart;


export default {
  name: "EChartsRelation",
  components: {VoiceModal, ChatModal},
  data() {
    return {
      spinning:true,
      textLanguageCode: [],
      audioLanguageCode:[],
      selectedTextLanguageCode: ['zhCN'],
      defaultAudioValue:{value: 'zhCN'},
      isContinuation:true,
    }
  },
  created() {
  },
  methods:{
    initNpcChart(elementId){
      var chartDom = document.getElementById(elementId);
      npc_chart = echarts.init(chartDom);
      var option;
      var that = this;

      npc_chart.showLoading();
      option = {
        tooltip: {
          show:false,
          formatter: (params) => {
            var tipText = '';
            npc_nodes.forEach(function (node) {
              if(node.id == params.data.source){
                tipText += node.name;
                tipText += " >> "
              }
              if(node.id == params.data.target){
                tipText += node.name;
              }
            });
            return tipText;
          },
        },
        animationDuration: 1500,
        animationEasingUpdate: 'quinticInOut',
        series: [
          {
            type: 'graph',
            layout: 'force',
            nodes: npc_nodes,
            categories: npc_categories,
            roam: true,
            label: {
              show: true,
              position: "bottom",
              distance: 5,
              fontSize: 18,
              align: "center",
              formatter: (params) => {
                if(params.data.type == 1){
                  return "";
                }else{
                  return params.data.name;
                }
              },
            },
            edgeSymbolSize:10,
            force: {
              repulsion: 90,
              gravity:0.035,
              edgeLength:600
            },
            emphasis: {
              focus: 'adjacency',
              lineStyle: {
                width: 15,
                label:""
              },
            },
            draggable: true
          },
        ],
      };
      npc_chart.setOption(option);
      npc_chart.hideLoading();

      option && npc_chart.setOption(option);

      npc_chart.on('click', function (params) {
        if(params.dataType == "edge"){
          that.$refs.chatModal.showModal();
          that.$refs.chatModal.getConversationList(params.data.source,params.data.target);
        }
        if(params.dataType == "node"){
          console.log(params.data.id)
          that.$refs.voiceModal.showModal();
          that.$refs.voiceModal.getVoiceGroupList(params.data.id,params.data.type);
        }
      });

      npc_chart.on('legendselectchanged', function(obj) {
        if(obj.name == '全选'){
          that.selectAll()
        }else if(obj.name == '全不选'){
          that.unSelectAll()
        }
      })
    },
    getNpcList() {
      var that = this;
      var audioCode = localStorage.getItem("AudioCode")
      var textCode = JSON.parse(localStorage.getItem("TextCodeNew")) + '';
      axios.get(api.getNpcListURL, {params: {languageCode: audioCode,textLanguageCode:textCode}}).then((response) => {
        npc_nodes = response.data.result.heroList;
        console.log(response.data.result);
        this.initNpcChart("npc_main")
        this.spinning = false;
      }).catch(function (error) {
        console.log(error);
        that.spinning = false;
        message.error('请至少选择一项文本语言！！！');
      });
    },
    getTextInternationalizationList(){
      axios.get(api.getTextInternationalizationURL).then((response) => {
        this.textLanguageCode = response.data.result.list;
      }).catch(function (error) {
        console.log(error);
      });
    },
    getAudioInternationalizationList(){
      axios.get(api.getAudioInternationalizationURL).then((response) => {
        this.audioLanguageCode = response.data.result.list;
      }).catch(function (error) {
        console.log(error);
      });
    },
    handleTextChange(value){
      location.reload()
      localStorage.setItem("TextCodeNew", JSON.stringify(value));
    },
    handleAudioChange(value){
      location.reload()
      localStorage.setItem("AudioCode", value.key);
    },
    focus(){
      console.log('focus');
    },
    continuationChange(checked){
      console.log(checked)
      if(checked){
        localStorage.setItem("isContinuation", "1");
      }else{
        localStorage.setItem("isContinuation", "0");
      }
    },
  },
  mounted() {
    var localAudioCode = localStorage.getItem("AudioCode");
    if(localAudioCode == null){
      localStorage.setItem("AudioCode", "zhCN");
    }else{
      this.defaultAudioValue = {value: localAudioCode.toString()};
    }

    var localTextCode = JSON.parse(localStorage.getItem("TextCodeNew"));
    if(localTextCode == null){
      localStorage.setItem("TextCodeNew", JSON.stringify(["zhCN"]));
    }else{
      this.selectedTextLanguageCode = localTextCode;
      console.log(this.selectedTextLanguageCode)
    }

    var isContinuationLocal = localStorage.getItem("isContinuation");
    if(isContinuationLocal == null){
      localStorage.setItem("isContinuation", "1");
    }else{
      if(isContinuationLocal == "1"){
        this.isContinuation = true;
      }else if(isContinuationLocal == "0"){
        this.isContinuation = false;
      }
    }

    this.getTextInternationalizationList();
    this.getAudioInternationalizationList();

    this.getNpcList()
  }
}
</script>

<style scoped>
.container {
  height: 100%;
  width: 100%;
  background-color: #FAFAFA;
  position: fixed;
}
</style>
