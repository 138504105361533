<template>
  <div>
      <a-modal v-model:visible="visible" width="50%" title="互动语音" @ok="hideModal" @cancel="hideModal">
        <ChatView ref="chatView"/>
      </a-modal>
  </div>
</template>
<script>
import { ref } from 'vue';
import ChatView from "@/components/ChatView";
const visible = ref(false);
export default {
  name: 'ChatModal',
  components: {
    ChatView,
  },
  setup(){
    return {
      visible,
      // handleOk,
    };
  },
  methods:{
    showModal(){
      visible.value = true;
      // var that = this;
      // setTimeout(function (){
      //   that.$refs.chatView.playVoice(0);
      // }, 1500);
    },
    hideModal(){
      visible.value = false;
      console.log(this)
      this.$refs.chatView.stopAllVoice();
      this.$refs.chatView.destroyWave();
      this.$refs.chatView.clearData();
      this.$refs.chatView.initTabActiveKey();
    },
    async getConversationList (sourceId,targetId) {
      this.$nextTick(() => {
        console.log(this.$refs.chatView)
        this.$refs.chatView.getConversationList(sourceId,targetId);
      })
    }
  }
}
</script>
