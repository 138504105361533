
<template>
    <div style="width: 200px;z-index: 11;position: fixed;margin-top: 15px;margin-left: 10px;display: flex;">文本：</div>
    <a-select
        v-model:value="selectedTextLanguageCode"
        mode="multiple"
        style="min-inline-size: 200px;z-index: 11;position: fixed;margin-top: 10px;margin-left: 50px;display: flex;"
        :options="textLanguageCode"
        @change="handleTextChange"
    />

    <div style="width: 200px;z-index: 10;position: fixed;margin-top: 65px;margin-left: 10px;display: flex;">音频：</div>
    <a-select
        v-model:value="defaultAudioValue"
        label-in-value
        style="width: 200px;z-index: 10;position: fixed;margin-top: 60px;margin-left: 50px;display: flex;"
        :options="audioLanguageCode"
        @change="handleAudioChange"
    />

    <div style="width: 200px;z-index: 10;position: fixed;margin-top: 120px;margin-left: 10px;display: flex;">连播：</div>
    <a-switch @change="continuationChange" v-model:checked="isContinuation" style="z-index: 10;position: fixed;margin-top: 120px;margin-left: 50px;display: flex;"/>

  <EChartsRelation/>
</template>

<script>
import EChartsRelation from "@/components/EChartsRelation";
import axios from "axios";
const apiPrefix = process.env.VUE_APP_API_PREFIX //请求前缀

const api = {
  getTextInternationalizationURL: apiPrefix + "/internationalization/getTextInternationalization",
  getAudioInternationalizationURL: apiPrefix + "/internationalization/getAudioInternationalization",
};

export default {
  name: 'App',
  components: {
    EChartsRelation,
  },
  data() {
    return {
      textLanguageCode: [],
      audioLanguageCode:[],
      selectedTextLanguageCode: ['zhCN'],
      defaultAudioValue:{value: 'zhCN'},
      isContinuation:true,
    }
  },
  created() {
    // var localAudioCode = localStorage.getItem("AudioCode");
    // if(localAudioCode == null){
    //   localStorage.setItem("AudioCode", "zhCN");
    // }else{
    //   this.defaultAudioValue = {value: localAudioCode.toString()};
    // }
    //
    // var localTextCode = JSON.parse(localStorage.getItem("TextCodeNew"));
    // if(localTextCode == null){
    //   localStorage.setItem("TextCodeNew", JSON.stringify(["zhCN"]));
    // }else{
    //   this.selectedTextLanguageCode = localTextCode;
    //   console.log(this.selectedTextLanguageCode)
    // }
  },
  mounted(){

    var localAudioCode = localStorage.getItem("AudioCode");
    if(localAudioCode == null){
      localStorage.setItem("AudioCode", "zhCN");
    }else{
      this.defaultAudioValue = {value: localAudioCode.toString()};
    }

    var localTextCode = JSON.parse(localStorage.getItem("TextCodeNew"));
    if(localTextCode == null){
      localStorage.setItem("TextCodeNew", JSON.stringify(["zhCN"]));
    }else{
      this.selectedTextLanguageCode = localTextCode;
      console.log(this.selectedTextLanguageCode)
    }

    var isContinuationLocal = localStorage.getItem("isContinuation");
    if(isContinuationLocal == null){
      localStorage.setItem("isContinuation", "1");
    }else{
      if(isContinuationLocal == "1"){
        this.isContinuation = true;
      }else if(isContinuationLocal == "0"){
        this.isContinuation = false;
      }
    }

    this.getTextInternationalizationList();
    this.getAudioInternationalizationList();
  },
  methods:{
    getTextInternationalizationList(){
      axios.get(api.getTextInternationalizationURL).then((response) => {
        this.textLanguageCode = response.data.result.list;
      }).catch(function (error) {
        console.log(error);
      });
    },
    getAudioInternationalizationList(){
      axios.get(api.getAudioInternationalizationURL).then((response) => {
        this.audioLanguageCode = response.data.result.list;
      }).catch(function (error) {
        console.log(error);
      });
    },
    handleTextChange(value){
      location.reload()
      localStorage.setItem("TextCodeNew", JSON.stringify(value));
    },
    handleAudioChange(value){
      location.reload()
      localStorage.setItem("AudioCode", value.key);
    },
    focus(){
      console.log('focus');
    },
    continuationChange(checked){
      console.log(checked)
      if(checked){
        localStorage.setItem("isContinuation", "1");
      }else{
        localStorage.setItem("isContinuation", "0");
      }
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#components-back-top-demo-custom .ant-back-top {
  bottom: 10px;
  left:20px;
}
#components-back-top-demo-custom .ant-back-top-inner {
  height: 30px;
  width: 60px;
  line-height: 30px;
  border-radius: 4px;
  background-color: #6c93b0;
  color: #fff;
  text-align: center;
  font-size: 15px;
}

.ant-select-selector{
  width: 100% !important;
}
</style>
