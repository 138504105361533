
<template>
  <div id="app">
  </div>
  <MyLayout/>
  <TipsModal ref="tipModal"/>
</template>

<script>

import MyLayout from "@/components/MyLayout";
import TipsModal from "@/components/TipsModal";

export default {
  name: 'App',
  components: {
    MyLayout,
    TipsModal
  },
  mounted(){
    var localAudioCode = localStorage.getItem("AudioCode");
    if(localAudioCode == null){
      localStorage.setItem("AudioCode", "zhCN");
    }else{
      this.defaultAudioValue = {value: localAudioCode.toString()};
    }

    var localTextCode = JSON.parse(localStorage.getItem("TextCodeNew"));
    if(localTextCode == null){
      localStorage.setItem("TextCodeNew", JSON.stringify(["zhCN"]));
    }else{
      this.selectedTextLanguageCode = localTextCode;
      console.log(this.selectedTextLanguageCode)
    }

    var isReadTip = localStorage.getItem("IsReadTip0422")
    if(isReadTip == null || isReadTip == 0){
      this.showTips();
    }
  },
  methods:{
    showTips(){
      this.$refs.tipModal.showModal();
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
/*html,body {*/
/*  !* 滤镜效果 *!*/
/*  filter: grayscale(100%);*/
/*  !* webkit内核 *!*/
/*  -webkit-filter: grayscale(100%);*/
/*  !* 火狐内核 *!*/
/*  -moz-filter: grayscale(100%);*/
/*  !* 微软内核 *!*/
/*  -ms-filter: grayscale(100%);*/
/*  !* 欧朋内核 *!*/
/*  -o-filter: grayscale(100%);*/
/*  !* ie专有滤镜 *!*/
/*  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);*/
/*}*/
</style>
