<template>
  <a-spin style="z-index: 9999;margin-top: 15%" :spinning="spinning" tip="加载中，请稍后...">
    <!-- 总共93%   -->
    <div id="main" class="container" style="height: 93%">
    </div>
  </a-spin>
  <ChatModal ref="chatModal"/>
  <VoiceModal ref="voiceModal"/>
</template>

<script>
import * as echarts from "echarts";
import ChatModal from "@/components/ChatModal";
import axios from "axios";
import VoiceModal from "@/components/VoiceModal";
import { message } from 'ant-design-vue';

const apiPrefix = process.env.VUE_APP_API_PREFIX //请求前缀

const api = {
  getHeroListURL: apiPrefix + "/wodata/getHeroList",
  getRelationshipListURL: apiPrefix + "/wodata/getRelationshipList",
  getConversationListURL: apiPrefix + "/wodata/getConversationList",
  getHeroListAndRelationshipListURL: apiPrefix + "/wodata/getHeroListAndRelationshipList",
};

let mynodes = [];
let links = [];
let mycategories = [];
var myChart;

export default {
  name: "EChartsRelation",
  components: {VoiceModal, ChatModal},
  data() {
    return {
      spinning:true
    }
  },
  created() {
  },
  methods:{
    initChart(elementId){
      var chartDom = document.getElementById(elementId);
      myChart = echarts.init(chartDom);
      var option;
      var that = this;

      myChart.showLoading();
      option = {
        tooltip: {
          show:false,
          formatter: (params) => {
            var tipText = '';
            mynodes.forEach(function (node) {
              if(node.id == params.data.source){
                tipText += node.name;
                tipText += " >> "
              }
              if(node.id == params.data.target){
                tipText += node.name;
              }
            });
            return tipText;
          },
        },
        animationDuration: 1500,
        animationEasingUpdate: 'quinticInOut',
        legend: [
          {
            data: mycategories.map(function (a) {
              return a.name;
            }),
            orient:'vertical',
            align:'right',
            right:10,
            itemGap:7,
          },

        ],
        series: [
          {
            type: 'graph',
            layout: 'circular',
            nodes: mynodes,
            links: links,
            categories: mycategories,
            roam: true,
            label: {
              show: false,
              position: "bottom",
              distance: 5,
              fontSize: 18,
              align: "center"
            },
            autoCurveness: true, //多条边的时候，自动计算曲率
            edgeLabel: {//边的设置
              show: false,
              position: "middle",
              fontSize: 12,
              // formatter: (params) => {
              //   console.log(params)
              //   return params.data.sourceName + '>>' + params.data.targetName;
              // },
            },
            edgeSymbol: ["none", "arrow"], //边两边的类型
            edgeSymbolSize:10,
            lineStyle: {
              color: 'source',
              width: 2,
              curveness: 0.2
            },
            force: {
              repulsion: 200,
              gravity:0.01,
              edgeLength:600
            },
            emphasis: {
              focus: 'adjacency',
              lineStyle: {
                width: 15,
                label:""
              },
            },
            draggable: true
          },
        ],
      };
      myChart.setOption(option);
      myChart.hideLoading();

      option && myChart.setOption(option);

      myChart.on('click', function (params) {
        if(params.dataType == "edge"){
          that.$refs.chatModal.showModal();
          that.$refs.chatModal.getConversationList(params.data.source,params.data.target);
        }
        if(params.dataType == "node"){
          console.log(params.data.id)
          that.$refs.voiceModal.showModal();
          that.$refs.voiceModal.getVoiceGroupList(params.data.id);
        }
      });

      myChart.on('legendselectchanged', function(obj) {
        if(obj.name == '全选'){
          that.selectAll()
        }else if(obj.name == '全不选'){
          that.unSelectAll()
        }
      })

      //15之后取消选择
      for (var i=15;i<mynodes.length;i++)
      {
        myChart.dispatchAction({
          type: 'legendUnSelect',
          // 图例名称
          name: mynodes[i].name
        });
      }
    },
    getHeroListAndRelationshipList() {
      var that = this;
      var audioCode = localStorage.getItem("AudioCode")
      var textCode = JSON.parse(localStorage.getItem("TextCodeNew")) + '';
      axios.get(api.getHeroListAndRelationshipListURL, {params: {languageCode: audioCode,textLanguageCode:textCode}}).then((response) => {
        mynodes = response.data.result.heroList;
        links = response.data.result.relationshipList;
        mycategories = response.data.result.categoriesList;
        console.log(response.data.result);
        this.initChart("main")
        this.spinning = false;
      }).catch(function (error) {
        console.log(error);
        that.spinning = false;
        message.error('请至少选择一项文本语言！！！');
      });
    },
    selectAll(){
      myChart.dispatchAction({
        type: 'legendAllSelect',
      });
    },
    unSelectAll(){
      for (var i=0;i<mynodes.length;i++)
      {
        myChart.dispatchAction({
          type: 'legendUnSelect',
          // 图例名称
          name: mynodes[i].name
        });
      }
    }
  },
  mounted() {
    this.getHeroListAndRelationshipList()
  }
}
</script>

<style scoped>
.container {
  height: 100%;
  width: 100%;
  background-color: #FAFAFA;
  position: fixed;
}
</style>
