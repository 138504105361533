<template>
  <section data-name="slide-4" class="slide fade-6 kenBurns">
    <div class="content">
      <div class="container">
        <div class="wrap">

          <div class="fix-6-12">
            <h1 class="huge ae-1 margin-bottom-2" style="font-weight: 400;color: #CDCED2"><b>OW Wiki</b></h1>

            <a-input-search
                placeholder="请输入关键字（暂时只支持中文！）"
                enter-button
                @search="onSearch" style="width: 100%;z-index: 10;"/>
            <!--            <p class="hero ae-2 margin-bottom-3"><span class="opacity-8" style="font-size: 25px">𝙇𝙞𝙛𝙚 𝙬𝙖𝙨 𝙖 𝙗𝙤𝙭 𝙤𝙛 𝙘𝙝𝙤𝙘𝙤𝙡𝙖𝙩𝙚𝙨,𝙮𝙤𝙪 𝙣𝙚𝙫𝙚𝙧 𝙠𝙣𝙤𝙬 𝙬𝙝𝙖𝙩 𝙮𝙤𝙪'𝙧𝙚 𝙜𝙤𝙣𝙣𝙖 𝙜𝙚𝙩.</span></p>-->

<!--            <a href="" class="button hollow ae-5"><img src="assets/img/appstore.jpg" height="63"/></a>-->
<!--            <a id="downloadApk" href="#slide-4" class="button hollow ae-6"><img src="assets/img/googleplay.jpg" height="63"/></a>-->

            <br>
            <br>
            <br>
<!--            <a-button type="primary" size="large" @click="changeMenu('morii')">
              <template #icon>
                <DownloadOutlined />
              </template>
              下载APP
            </a-button>-->
            <br>
            <br>
            <br>

            <h2 class="huge ae-1 margin-bottom-2" style="font-weight: 400;color: #f59707;font-style: italic">特别嘉奖</h2>
            <a class="button hollow ae-5" href="https://space.bilibili.com/2018356/" target="_blank" title="Moonpie-3-">
              <img style="border-radius: 50%;border: 2px solid hsla(0,0%,100%,.4);" src="../assets/commend/2018356.jpg" width="50"/>
            </a>
            <a class="button hollow ae-5" href="https://space.bilibili.com/525804252/" target="_blank" title="乱花葬不是乱葬岗">
              <img style="border-radius: 50%;border: 2px solid hsla(0,0%,100%,.4);" src="../assets/commend/525804252.jpg" width="50"/>
            </a>
            <a class="button hollow ae-5" href="https://space.bilibili.com/667007/" target="_blank" title="木木木ちゃん">
              <img style="border-radius: 50%;border: 2px solid hsla(0,0%,100%,.4);" src="../assets/commend/667007.jpg" width="50"/>
            </a>
            <a class="button hollow ae-5" href="https://space.bilibili.com/327379772/" target="_blank" title="炫酷逍遥_">
              <img style="border-radius: 50%;border: 2px solid hsla(0,0%,100%,.4);" src="../assets/commend/327379772.jpg" width="50"/>
            </a>

            <a class="button hollow ae-5" href="https://space.bilibili.com/471465262/" target="_blank" title="不配shy">
              <img style="border-radius: 50%;border: 2px solid hsla(0,0%,100%,.4);" src="../assets/commend/471465262.jpg" width="50"/>
            </a>

            <a class="button hollow ae-5" href="https://space.bilibili.com/286243845/" target="_blank" title="局不过">
              <img style="border-radius: 50%;border: 2px solid hsla(0,0%,100%,.4);" src="../assets/commend/286243845.jpg" width="50"/>
            </a>

            <a class="button hollow ae-5" href="https://space.bilibili.com/333374230/" target="_blank" title="杀生院肉肉">
              <img style="border-radius: 50%;border: 2px solid hsla(0,0%,100%,.4);" src="../assets/commend/333374230.jpg" width="50"/>
            </a>

            <a class="button hollow ae-5" href="https://space.bilibili.com/1471255/" target="_blank" title="是快乐乐高迷呀">
              <img style="border-radius: 50%;border: 2px solid hsla(0,0%,100%,.4);" src="../assets/commend/1471255.jpg" width="50"/>
            </a>
          </div>


          <br>
          <br>
          <br>

<!--          <a class="button hollow ae-5" href="https://space.bilibili.com/67787489" target="_blank"><img src="../assets/svg/bilibili.svg" width="25"/></a>-->
<!--          <a class="button hollow ae-5" href="" target="_blank"><img src="../assets/svg/Wechat.svg" width="25"/></a>-->
<!--          <a class="button hollow ae-5" href="" target="_blank"><img src="../assets/svg/QQ.svg" width="25"/></a>-->

        </div>
      </div>
    </div>
<!--    <div class="background" style="background-image:url(https://ow-club.oss-cn-shanghai.aliyuncs.com/website/background/TGENJI.jpg)"></div>-->
    <div class="background" style="background-image:url(https://ow-club.oss-cn-shanghai.aliyuncs.com/image/Lifeweaver.jpg)"></div>
  </section>
</template>

<script>
import "@/assets/css/slides.css"
/*
import { DownloadOutlined } from '@ant-design/icons-vue';
*/
import {message} from "ant-design-vue";

export default {
  name: "HomeView",
  /*components:{
    DownloadOutlined
  },*/
  methods:{
    //路由内容切换
    changeMenu(route){
      console.log(this.$router)
      //获取路由对象并切换
      this.$router.push('/' + route)
    },
    onSearch(searchVal){
      if(searchVal == undefined || searchVal == " "){
        message.error('请输入搜索内容（不能全是空格哦，不然服务器会炸的，不信你试试）！！！');
      }else{
        console.log(searchVal)
        this.$router.push({path: '/search',query:{ searchText:searchVal}});
      }
    }
  },
}
</script>
<style scoped>
/*.my-glass{*/
/*  height: 100vh;*/
/*  overflow-y: hidden;*/
/*  overflow-x: hidden;*/
/*  z-index: 9999;*/
/*  !*background: rgba(0, 0, 0, .5);*!*/
/*  backdrop-filter: blur(5px);*/
/*}*/
/*.home-ground{*/
/*  height: 100vh;*/
/*  overflow-y: hidden;*/
/*  margin: 0 auto;*/
/*  background-image: url("https://ow-club.oss-cn-shanghai.aliyuncs.com/img/bulijita.png");*/
/*  background-size: cover;*/
/*  background-repeat: no-repeat;*/
/*}*/

.slide:first-child, .slide.after {
  transform: translate3d(0, 0%, 0);
  will-change: transform;
}
</style>
