<template>
  <div style="background-color: #ececec; padding: 20px">
    <a-row :gutter="16">
      <a-col :span="4" v-for="i in mapList.length" :key="i"  @click="toPage(mapList[i-1].id)">

        <a-badge-ribbon text="新地图" v-if="(mapList[i-1].showOrder != null && mapList[i-1].showOrder <= 6) || mapList[i-1].showOrder == 9">
          <a-card hoverable style="margin-bottom: 20px">
            <template #cover>
              <img alt="example" :src="mapList[i-1].coverImageUrl" />
            </template>
            <a-card-meta :title="mapList[i-1].name">
            </a-card-meta>
          </a-card>
        </a-badge-ribbon>

        <a-badge-ribbon color="purple" text="大厅" v-if="mapList[i-1].showOrder != null && mapList[i-1].showOrder > 6 &&  mapList[i-1].showOrder < 9">
          <a-card hoverable style="margin-bottom: 20px">
            <template #cover>
              <img alt="example" :src="mapList[i-1].coverImageUrl" />
            </template>
            <a-card-meta :title="mapList[i-1].name">
            </a-card-meta>
          </a-card>
        </a-badge-ribbon>

        <a-card hoverable style="margin-bottom: 20px" v-if="mapList[i-1].showOrder == null">
          <template #cover>
            <img alt="example" :src="mapList[i-1].coverImageUrl" />
          </template>
          <a-card-meta :title="mapList[i-1].name">
          </a-card-meta>
        </a-card>

      </a-col>
    </a-row>
  </div>
</template>

<script>
import axios from "axios";

const apiPrefix = process.env.VUE_APP_API_PREFIX //请求前缀

const api = {
  getMapListURL: apiPrefix + "/wodata/getMapList",
};
export default {
  name: "MapList",
  data() {
    return {
      mapList: []
    }
  },
  methods:{
    //路由内容切换
    toPage(mapId){
      console.log(mapId)
      //获取路由对象并切换
      this.$router.push({path: '/mapDetails', query: {mapId: mapId}})
    },
    async getMapList () {
      this.$nextTick(() => {
        axios.get(api.getMapListURL).then((response) =>  {
          console.log(response.data.result.list);
          this.mapList = response.data.result.list;
        }).catch(function (error) {
          console.log(error);
        });
      })
    },
  },
  mounted() {
    this.getMapList();
  }
}
</script>



<style>
.ant-card-body{
  padding: 10px !important;
}

body{
  background-color:#ececec!important;
}
.ant-card-bordered {
   border: 2px solid #98a2c8;
 }

.ant-card-hoverable {
  cursor: pointer;
  transition: box-shadow 0.3s, border-color 0.3s;
}
</style>
