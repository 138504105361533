<template>
  <section class="slide fade-6 kenBurns" style="z-index: -999">
    <div class="content">
      <div class="container">
        <div class="wrap">
        </div>
      </div>
    </div>
    <div class="background" :style="backgroundStyle" ></div>
  </section>

  <div id="back-btn" >
    <a-back-top :visibilityHeight="-10" @click="backPage">
      <a-button type="primary">返 回</a-button>
    </a-back-top>
  </div>

  <div id="ttt-image" >
    <a-back-top :visibilityHeight="-10">
      <div class="ant-back-top-inner">
        <img alt="example" :src="territorioImageUrl" />
      </div>
    </a-back-top>
  </div>

  <a-row :justify="center" v-for="i in mapDetails.length" :key="i" style="padding-top: 20px;padding-bottom: 20px;background-color: transparent!important;">
    <a-col :span="16" style="margin: 0 auto">
      <a-card hoverable @click="playVoice(mapDetails[i-1].index)" style="background: #3462a6;border: 1px solid #70adf7;">
        <div style="padding-bottom: 10px;color: #FFFFFF">{{mapDetails[i-1].soundTitle}}</div>
        <p :id="'waveform' + mapDetails[i-1].id " style="cursor: pointer;"></p>
      </a-card>
    </a-col>
  </a-row>

<!--  <a-row :gutter="16" @click="playVoice(mapDetails[i-1].index)" v-for="i in mapDetails.length" :key="i" style="padding-top: 20px;padding-bottom: 20px">-->
<!--    <a-col :span="16">-->
<!--      <a-card hoverable>-->
<!--        <div>{{mapDetails[i-1].soundTitle}}</div>-->
<!--        <p :id="'waveform' + mapDetails[i-1].id "></p>-->
<!--      </a-card>-->
<!--    </a-col>-->
<!--  </a-row>-->

<!--  <a-card hover @click="playVoice(mapDetails[i-1].index)" v-for="i in mapDetails.length" :key="i">-->

<!--  </a-card>-->

</template>

<script>
import WaveSurfer from "wavesurfer.js";
import axios from "axios";

const apiPrefix = process.env.VUE_APP_API_PREFIX //请求前缀

const api = {
  getMapDetailsURL: apiPrefix + "/wodata/getMapDetails",
};


export default {
  name: "MapDetails",
  data() {
    return {
      mapDetails:[],
      backgroundStyle:{},
      wavesurferList:[],
      territorioImageUrl:""
    }
  },
  methods:{
    initWavesurffer(containerId,audioUrl){
      this.$nextTick(() => {
        console.log(containerId);
        var wavesurfer = WaveSurfer.create({
          container: "#" + containerId,
          waveColor: '#b0fafb6e',
          progressColor: '#b0fafb',
          height: "40",
          interact: false,
          hideScrollbar: true,
        });
        wavesurfer.load(audioUrl);
        this.wavesurferList.push(wavesurfer);
      })
    },
    stopAllVoice(){
      for (var i=0; i < this.wavesurferList.length;i++)
      {
        this.wavesurferList[i].stop()
      }
    },
    playVoice(index){
      this.stopAllVoice();
      this.wavesurferList[index].play();
    },
    async getMapDetails (mapId) {
      this.$nextTick(() => {
        axios.get(api.getMapDetailsURL,{params:{mapId:mapId}}).then((response) =>  {
          console.log(response.data.result.list);
          this.mapDetails = response.data.result.list;
          console.log(this.mapDetails);
          this.backgroundStyle = { backgroundImage: 'url(' + this.mapDetails[0].realImageUrl  + ')' };
          this.territorioImageUrl = this.mapDetails[0].territorioImageUrl;
          if(this.mapDetails.length > 0){
            for (var i=0;i < this.mapDetails.length;i++)
            {
              this.initWavesurffer('waveform' + this.mapDetails[i].id,this.mapDetails[i].soundUrl);
            }
          }
        }).catch(function (error) {
          console.log(error);
        });
      })
    },
    backPage(){
      this.$router.back()
    }
  },
  mounted() {
    var mapId =  this.$route.query.mapId;
    console.log(mapId);
    this.getMapDetails(mapId);
    // this.initWavesurffer("waveform","https://ow-club.oss-cn-shanghai.aliyuncs.com/owdata/2.1.1.0.106617/map/00000020D4C3.03F.ogg")
  },
  beforeUnmount(){
    this.stopAllVoice();
  }
}
</script>

<style scoped>
.background{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  backface-visibility: hidden;
  background-color: indianred;
}

.slide:first-child, .slide.after {
  transform: translate3d(0, 0%, 0);
  will-change: transform;
}

.ant-col .ant-col-16{
  margin: 0 auto !important;
}

section{
  background-color: transparent!important;
}

#ttt-image .ant-back-top {
  bottom: 30px;
  left:30px;
}
#ttt-image .ant-back-top-inner {
  height: 54px;
  width: 90px;
  line-height: 30px;
  border-radius: 4px;
  background-color: #6c93b0;
  color: #fff;
  text-align: center;
  font-size: 15px;
}

#back-btn .ant-back-top {
  bottom: 20px;
  right:80px;
}

#back-btn .ant-back-top-inner {
  height: 54px;
  width: 60px;
  line-height: 30px;
  border-radius: 4px;
  background-color: #6c93b0;
  color: #fff;
  text-align: center;
  font-size: 15px;
}

</style>
